@import "../../../../public/stylesheets/index.scss";

.news-section {
  width: 100%;
  min-height: 637px;
  padding: 70px 165px 30px;
  @include backgroundImage("../../../assets/images/news.png", cover);

  &__wrapper {
    .title {
      @include flexible("", "", center);
      color: $primary-color;
      font-size: 50px;
      font-weight: 400;
    }

    .news-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 297px);
      justify-content: center;
      gap: 30px;
      margin: 27px 0;
    }

    .news-section-see-more-btn {
      @include flexible("", "", center);
    }
  }
}

.news-card {
  width: 297px;
  cursor: pointer;

  .news-image {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    width: 297px;
    height: 271px;
    display: block;
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center;
  }

  &__bottom {
    background-color: #ffffff;
    opacity: 0.7;
    padding: 10px 29px 21px;
    height: 182px;
    color: #191b1e;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    &__createdAt{
      margin-bottom: 10px;
      font-size: 14px;
    }

    &__title {
      font-weight: 700;
      font-size: 21px;
      margin-bottom: 8px;
    }

    &__description {
    }
  }
}

@media screen and (max-width: 970px) {
  .news-wrapper {
    grid-template-columns: repeat(auto-fit, minmax(297px, 297px)) !important; 
  }
}
