.err_container {
  height: calc(100vh - 133px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .text {
    margin-top: 38px;
    color: #ff517b;
    font-size: 31px;
    font-weight: 700;
    text-align: center;
  }
}
