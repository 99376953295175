@import "../../../../public//stylesheets/index.scss";

header {
  height: 98px;
  z-index: 99;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  @include flexible("", center, center);

  .wrapper {
    padding: 10px 0;
    @include flexible("", center, space-between);

    .menu-icon {
      display: none;
    }

    .logo-wrapper {
      cursor: pointer;
      background-color: #fff;
      padding: 14px 50px;
      border-radius: $border-radius;
      box-shadow: $box-shadow;

      img {
        width: 134px;
        object-fit: contain;
      }
    }

    .menu-wrapper {
      padding: 8px;
      background-color: #fff;
      border-radius: $border-radius;
      box-shadow: $box-shadow;

      .menu-list {
        list-style: none;
        @include flexible("", center, flex-end);

        .menu-list__item {
          padding: 18px 34px;
          border-radius: $border-radius;
          cursor: pointer;

          &:not(&:nth-child(1)) {
            margin-left: 22px;
          }

          &:hover {
            background-color: $menu-item-hover-color !important;
          }

          a {
            color: #353535;
            font-size: 24px;
            text-decoration: none;
          }
        }

        // .menu-list__item.active-link {
        //   background-color: $menu-item-hover-color !important;
        // }
      }

      
    }
  }
}
.sidebar-drawer-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  visibility: hidden;
  width: 100%;
  height: 100vh;
  background: hsla(0, 0%, 93%, 0.698);
  transition: visibility 0.2s linear;

  .empty {
    height: 100vh;
  }

  .sidebar-drawer {
    background: #ffffff;
    position: fixed;
    right: 0;
    top: 0;
    padding: 46px 22px;
    border-radius: 42px;
    height: calc(100vh - 20px);
    width: 363px;
    margin-right: 10px;
    margin-top: 10px;
    transform: translateX(100%);
    transition: transform 0.3s linear;

    &-title {
      @include flexible("", center, space-between);
      margin-bottom: 34px;

      .sidebar-close-icon {
        cursor: pointer;
        margin-right: 3px;
      }
    }

    &-content {
      &__menu {
        list-style: none;

        &-item {
          font-size: 20px;
          padding: 12px;
          border-bottom: 1px solid rgba(24, 24, 24, 0.24);
          margin-bottom: 5px;
          cursor: pointer;

          a {
            text-decoration: none;
            color: #181818;
          }
        }
      }
    }

    &.open {
      transform: translateX(0);
    }
  }

  &.show {
    visibility: visible;
  }
}

@media screen and (max-width: 500px) {
  .logo-wrapper {
    padding: 17px 42px !important;

    img {
      width: 84px !important;
    }
  }
}

@media screen and (max-width: 1415px) {
  .menu-wrapper {
    display: none;
  }

  .logo-wrapper {
    padding: 12px 50px !important;
  }

  .menu-icon {
    display: block !important;
    cursor: pointer;
    background-color: #fff;
    padding: 23px 38px;
    border-radius: $border-radius;
    box-shadow: $box-shadow;

    .menu-icon-container {
      @include flexible("", "", "");
    }
  }
}

@media screen and (max-width: 1600px) {
  .logo-wrapper {
    img {
      width: 123px !important;
    }
  }

  .menu-list {
    a {
      font-size: 20px !important;
    }
  }
}
