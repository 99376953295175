@mixin flexible($fd: "", $ai: "", $jc: "") {
  display: flex;
  flex-direction: unquote($fd);
  align-items: unquote($ai);
  justify-content: unquote($jc);
}

@mixin backgroundImage($url, $bgsize) {
  background-image: url($url);
  background-size: $bgsize;
  background-position: center;
  background-repeat: no-repeat;
}

@mixin input($borderColor, $placeholderColor) {
  background-color: transparent;
  border: none;
  border-bottom: 3px solid $borderColor;
  outline: none;
  width: 100%;
  font-size: 15px;
  padding: 5px 0;
  margin-top: 50px;

  &::placeholder {
    color: $placeholderColor;
  }
}
