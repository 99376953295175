@import ".././../../../public/stylesheets/index.scss";

.help-section {
  padding: 165px 78px;
  width: 100%;
  @include flexible(column, center, center);

  &__title {
    color: $primary-color;
    font-size: 88px;
    font-weight: 400;
    margin-bottom: 57px;
  }

  &__alert {
    text-align: center;
    font-size: 35px;
    color: $primary-color-light;
    display: block;
    margin-bottom: 24px;
    font-weight: 600;
  }

  &__desc {
    color: $secondary-color;
    font-size: 20px;
    text-align: center;
  }

  &__btn {
    margin-top: 24px;
    border: transparent;
    border-radius: 8px;
    box-shadow: 0px 4px 12px #00000029;
    background: #fff;
    color: $primary-color-light;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;

    a {
      color: $primary-color-light;
      text-decoration: none;
    }

    &:hover {
      a {
        color: $secondary-color;
      }
    }
  }

  &__txt {
    font-size: 30px;
    color: $secondary-color;
    font-weight: 600;
    text-align: center;
  }

  &__mail {
    color: $primary-color-light;
    text-decoration: none;
    margin-left: 10px;
  }
}

@media screen and (max-width: 1600px) {
  .help-section__title {
    font-size: 50px;
  }
}

@media screen and (max-width: 757px) {
}
