@import "../../../../public/stylesheets/index.scss";

.registration-page {
  width: 100%;
  padding-top: 120px;
  padding-bottom: 373px;
  @include backgroundImage("../../../assets/images/registration.png", cover);
  @include flexible("", "", center);

  &__title {
    color: $primary-color;
    font-size: 88px;
    font-weight: 400;
    text-transform: uppercase;
  }

  .registration-wrapper {
    width: 100%;
    @include flexible(column, center, center);

    &-content {
      @include flexible(column, "", center);

      .registration-form {
        width: 428px;
        @include flexible(column, "", "");

        &__inp {
          @include input($primary-color-light, $primary-color-light);
        }

        .registration-btn{
           @include flexible("", center, flex-end);
        }
      }

      .desc {
        color: $secondary-color;

        &-txt {
          font-size: 24px;
        }

        &-list {
          margin-left: 19px;
          margin-top: 10px;
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 592px) {
  .registration-form {
    width: 352px !important;
    margin: auto;
  }

  .desc-txt {
    font-size: 18px !important;
  }

  .desc-list {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1600px) {
  .registration-page {
    padding-bottom: 160px;
  }

  .registration-page__title {
    font-size: 50px;
  }

  .desc {
    &-txt {
      font-size: 18px !important;
    }

    &-list {
      font-size: 16px !important;
    }
  }
}
