@import "../../public/stylesheets/index.scss";

.btn {
  color: #fff;
  background-color: #8e8c8c;
  border-color: transparent;
  border-radius: 8px;
  font-size: 16px;
  padding: 12px 29px;
  cursor: pointer;

  &:hover{
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  }
}

.i-red {
  color: #ee2429;
}

.terms-wrapper {
  padding-top: 138px;
  padding-bottom: 30px;

  .terms-holder {
    max-width: 1140px;
    margin: 0 auto;
    color: $primary-color-light;
    line-height: 1.5;

    .privacy-title {
      margin: 10px 0 18px 0;
      font-family: inherit;
      font-weight: 500;
      line-height: 1.2;
    }

    .privacy-list {
      margin: 10px 45px;
    }

    .i-red-bold {
      font-weight: bolder;
      font-size: 22px;
    }

    .alert-danger {
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
      padding: 0.75rem 1.25rem;
      border: 1px solid transparent;
      border-radius: 0.25rem;
    }

    hr {
      margin: 20px 0;
    }

    .terms-link {
      color: #007bff;
      text-decoration: none;
    }
  }
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  @include flexible("", center, center);
  z-index: 999;

  .modal-content {
    padding: 65px;
    background-color: #fff;
    box-shadow: 0px 4px 12px #00000029;
    border-radius: 12px;
    position: relative;
    // width: 954px;
    // height: 536px;

    .close-btn {
      cursor: pointer;
      padding: 10px;
      @include flexible("", "", flex-end);
      position: absolute;
      right: 39px;
      font-size: 25px;
      top: 18px;
    }
  }
}

.message {
  height: 0;
  transition: height 0.2s ease-in-out;
  font-style: italic;
  margin-top: 5px;

}

.success{
	color:#198754;
}

.danger {
  color: $error-message-color;
}

.message.open {
  overflow: hidden;
  height: 50px;
}

@media screen and (max-width:800px) {
  .modal {

    .modal-content {
      padding:45px !important;

    }
  }
}