@import "/public/stylesheets/index.scss";

.news-page {
  min-height: 100vh;
  padding: 140px 75px 60px;

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(4, minmax(297px, 297px));
    grid-gap: 30px;
    justify-content: center;
  }
}

.current-news-page {
  min-height: 100vh;
  padding: 140px 75px;

  &__top {
    @include flexible(column, "", center);
  }

  &__current-news {
    @include flexible("", "", "");
    gap: 60px;
    margin-top: 25px;

    &__createdAt{
      color: #8e8e8e;
      font-size: 14px;
      margin-bottom: 10px;
    }

    &__title {
      color: #1a1a1a;
      margin-bottom: 26px;
      font-size: 32px;
      font-weight: 700;
    }

    &__img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: bottom;
    }

    &__description {
      color: #8e8e8e;
      font-style: 16px;
      font-weight: 400;
      line-height: 22px;
    }
  }

  .title {
    color: #1f1f1f;
    font-style: 20px;
    margin-bottom: 27px;
  }

  &__back-btn {
    cursor: pointer;
  }

  &__similar-news {
    margin-top: 40px;
  }

  &__bottom {
    display: grid;
    grid-template-columns: repeat(4, minmax(297px, 1fr));
    gap: 30px;
  }
}

@media screen and (max-width: 1400px) {
  .news-page {
    &__wrapper {
      grid-template-columns: repeat(auto-fit, minmax(297px, 297px));
    }
  }

  .current-news-page__bottom {
    grid-template-columns: repeat(auto-fit, minmax(297px, 297px));
    gap: 74px;
  }
}

@media screen and (max-width: 817px) {
  .current-news-page__current-news {
    @include flexible(column, "", "");
  }
  .current-news-page__bottom {
    gap: 10px !important;
  }
  .current-news-page__current-news__img {
    width: 100%;
  }
}
