@import "../../../public/stylesheets/index.scss";

.header-wrapper {
  @include flexible("", center, center);

  .header {
    padding: 8px;
    margin: auto;
    @include flexible("", center, space-between);

    .logo-wrapper {
      cursor: default;
      background-color: #fff;
      padding: 7px 50px;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      width: max-content;
      @include flexible("", "", center);

      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

.logout {
  width: max-content;
  padding: 15px 34px;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  cursor: pointer;
  color: #353535;
  font-size: 20px;

  &:hover {
    background-color: $menu-item-hover-color;
  }
}

.devices-page-container {
  min-height: 100vh;
  @include flexible(column, center, "");

  .devices-page__title {
    font-size: 88px;
    font-weight: 400;
    margin-bottom: 84px;
    text-align: center;
  }

  .devices-page__btn-container {
    @include flexible("", center, flex-end);
    margin-bottom: 20px;

    .add-device-btn {
      @include flexible("", center, center);
      gap: 27px;
      font-size: 24px;
    }
  }

  .single-devices-container {
    box-shadow: 0px 4px 12px #00000029;
    border-radius: 18px;
    background-color: #fff;
    margin-bottom: 20px;
    padding: 23px;

    .device-desc-container {
      @include flexible("", flex-end, space-between);

      .device-desc {
        @include flexible("", center, "");
        gap: 80px;

        .single-device {
          font-size: 24px;
          color: $primary-color-light;
          margin-bottom: 12px;
        }
      }

      .arrow {
        cursor: pointer;
        margin: 10px;
      }
    }

    .visible {
      display: block;
    }

    .hide {
      display: none;
    }

    .playlists {
      font-size: 34px;
      font-weight: normal;
      color: $primary-color-light;
      margin: 50px 0 30px 28px;
    }

    .content {
      background-color: #ebe4e4;
      border-radius: 8px;
      padding: 13px;

      .content-playlist__title-name {
        width: 25vw;
      }

      &-playlist__title {
        @include flexible("", "", "");
        margin-bottom: 13px;

        &-name,
        &-url {
          color: $primary-color-light;
        }
      }

      &-playlist__row {
        @include flexible("", "", "");
        margin-bottom: 13px;

        .content-playlist__name-url {
          @include flexible("", center, "");
          background-color: #fff;
          border-radius: 8px;
          width: 100%;

          .content-playlist__url,
          .content-playlist__name {
            padding: 17px 24px;
            color: $primary-color-light;
            font-size: 18px;
            @include flexible("", center, "");
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline;
            white-space: nowrap;
          }

          .content-playlist__name {
            width: 25vw;
          }

          .content-playlist__url {
            width: 50%;
            // word-break: break-all;
          }

          .divider {
            min-height: 48px;
            border-right: 1.7px solid #b5b3b3;
          }
        }

        .content-playlist__edit-delete {
          @include flexible();

          .content-playlist__edit,
          .content-playlist__delete {
            background-color: #fff;
            border-radius: 8px;
            padding: 20px;
            cursor: pointer;
            @include flexible("", center, "");
          }
        }
      }

      .add-icon-container {
        @include flexible("", "", flex-end);
      }
    }

    .add-icon-container {
      @include flexible("", "", flex-end);
    }
  }
}

.add-device-modal {
  &__title {
    font-size: 34px;
    color: $primary-color-light;
    text-align: center;
  }

  &__form {
    width: 422px;
  }

  .add-device-inp {
    @include input($primary-color-light, $secondary-color);
  }

  &__btn-container {
    @include flexible("", center, flex-end);
  }
}

.add-playlist-tabs {
  @include flexible("", center, center);
  margin: 40px 0;
  box-shadow: 0px 4px 6px #00000029;
  border-radius: 10px;
  background-color: #fff;
  width: max-content;

  &__url,
  &__file {
    padding: 10px 47px;
    cursor: pointer;
    border-radius: 10px;

    &.active {
      background: #f01d23;
      color: #fff;
    }
  }
}

input[type="file"] {
  margin-top: 30px;

  &::file-selector-button {
    background-color: #f01d23;
    border: transparent;
    color: #fff;
    padding: 14px 26px;
    font-size: 21px;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
  }
}

.add-playlist-modal-content {
  @include flexible(column, center, center);
  margin: 0 70px;

  .add-playlist-modal-title {
    font-size: 34px;
    color: $primary-color-light;
    margin-bottom: 35px;
    text-align: center;
  }

  .add-playlist-form {
    @include flexible(column, center, center);
    width: 422px;

    .add-playlist-inp {
      @include input($primary-color-light, $secondary-color);
      margin-top: 0;
    }

    .add-playlist-btn {
      margin-top: 50px;
      @include flexible("", center, center);
    }
  }
}

.edit-playlist-title {
  font-size: 34px;
  color: $primary-color-light;
  text-align: center;
}

.edit-playlist-form {
  width: 422px;

  &__inp {
    @include input($primary-color-light, $secondary-color);
  }

  .save-btn {
    @include flexible("", center, flex-end);
    margin-top: 50px;
  }
}

@media screen and (max-width: 1600px) {
  .holder {
    width: 70%;
  }

  .devices-page__title {
    font-size: 50px !important;
    margin-bottom: 54px !important;
  }
}

@media screen and (max-width: 810px) {
  .add-device-modal {
    &__form {
      width: auto !important;
    }
  }

  .add-playlist-modal-content {
    .add-playlist-form {
      width: auto !important;
    }
  }

  .edit-playlist-form{
    width: auto !important;
  }

  .single-devices-container {
    padding: 10px !important;
  }

  .devices-page__title {
    font-size: 30px !important;
  }

  .devices-page__btn-container {
    .add-device-btn {
      font-size: 16px !important;
    }
  }

  .device-desc-container {
    .device-desc {
      gap: 30px !important;

      img {
        width: 70px;
      }

      .single-device {
        font-size: 16px !important;
      }
    }

    .arrow {
      svg {
        width: 18px !important;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .header {
    .logo-wrapper {
      padding: 10px 5px !important;

      img {
        width: 50% !important;
      }
    }

    .logout {
      padding: 12px 34px !important;
      font-size: 13px !important;
    }
  }
}

@media screen and (max-width: 425px) {
  .device-desc-container {
    .device-desc {
      gap: 8px !important;

      img {
        width: 50px;
      }

      .single-device {
        font-size: 10px !important;
      }
    }

    .arrow {
      svg {
        width: 18px !important;
      }
    }
  }

  .content-playlist__edit,
  .content-playlist__delete {
    padding: 10px !important;
  }
}
