.success_container {
  height: calc(100vh - 133px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .texts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &_top,
    &_ok {
      color: #69f0ae;
      font-weight: 700;
      font-size: 31px;
    }

    &_ok {
      font-size: 64px;
    }
  }
}
