@import "../../../../public/stylesheets/index.scss";

.footer-section {
  width: 100%;
  color: #ffffff;
  background-color: $secondary-color;
  padding: 40px 0 40px 75px;
  font-size: 18px;
  @include flexible("", center, "");
  gap: 108px;

  a {
    text-decoration: none;
    color: #ffffff;
  }

  &-col-2 {
    @include flexible("", center, "");

    .footer-menu {
      list-style: none;
      cursor: pointer;
      @include flexible("", center, "");
      gap: 108px;
    }
  }

  .i-red {
    cursor: pointer;
  }
}

@media screen and (max-width: 1208px) {
  .footer-section {
    gap: 58px;

    &-col-2 {
      .footer-menu {
        gap: 58px;
      }
    }
  }
}

@media screen and (max-width: 937px) {
  .footer-section {
    @include flexible(column, flex-start, "");
    padding: 51px 0 45px 57px;
    gap: 32px;

    .i-red-logo-container {
      width: 100%;
      @include flexible("", "", center);
    }

    &-col-2 {
      .footer-menu {
        @include flexible(column, start);
        gap: 32px;
      }
    }
  }
}
