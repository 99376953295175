@import "../../../public/stylesheets/index.scss";

.reset-page-container {
  min-height: calc(100vh - 133px);
  @include flexible("", center, center);
c

  .holder {
    @include flexible("", center, center);
  }

  &__content {
    @include flexible("", "", center);
    gap: 100px;
    margin-top: 79px;

    &__form {
      &__input {
        @include input($primary-color-light, $primary-color-light);
      }

      .form-bottom {
        @include flexible("", center, space-between);
        gap: 30px;
        margin-top: 40px;

        a {
          text-decoration: none;
          color: #3d53ed;
          font-style: italic;
          font-size: 15px;
          font-weight: 600;
        }
      }
    }

    &__description {
      .title {
        color: $primary-color;
        margin-bottom: 24px;
      }

      .txt {
        color: $secondary-color;
      }
    }
  }

  .form-bottom {
    .btn {
      color: #fff;
      background-color: #8e8c8c;
      border-color: transparent;
      border-radius: 8px;
      font-size: 16px;
      padding: 12px 29px;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      }
    }
  }

  .reset-pass-container {
    width: 25.5rem;

    &__title {
      font-size: 22px;
      color: $primary-color;
      font-weight: 500;

      .email {
        color: #ff0000;
      }
    }

    &__form {
      &__inp {
        @include input($primary-color-light, $primary-color-light);
      }
    }
    .form-bottom {
      width: 100%;
      @include flexible("", "", flex-end);
      margin-top: 40px;
    }
  }

 
  .toast {
    margin-top: 79px;
  }
}

@media screen and (max-width: 950px) {
  .reset-page-container {
    &__content {
      flex-direction: column;
      align-items: center;
    }
  }
}

@media screen and (max-width: 500px) {
  .reset-pass-container {
    width: 20rem !important;

    &__title {
      font-size: 20px !important;
    }
  }

  .reset-page-container__content__form {
    width: 20rem !important;
  }

  .reset-page-container__content__description {
    width: 20rem !important;

    .title {
      font-size: 26px;
    }
  }

  .form-bottom {
    a{
      font-size: 11px !important;
    }

    .btn {
      font-size: 14px !important;
      padding: 10px !important;
    }
  }
}
