@import "../../../../public/stylesheets/index.scss";

.reseller-section {
  padding: 82px 0;
  width: 100%;
  @include backgroundImage("../../../assets/images/reseller.png", cover);
  @include flexible("", "", center);

  &__content {
    @include flexible(column, center, center);

    &-title {
      color: $primary-color;
      font-size: 88px;
      font-weight: 400;
      text-transform: uppercase;
      text-align: center;
    }

    &__wrapper {
      @include flexible("", "", center);
      margin-top: 67px;

      .become-reseller-form-content {
        margin: 0 55px;

        &-title {
          color: $primary-color-light;
          font-size: 24px;
          font-weight: bold;
        }

        .become-reseller-form {
          width: 428px;

          &__inp {
            @include input($primary-color-light, $primary-color-light);
          }

          .phone-input-container {
            @include flexible("", start, center);
            gap: 10px;

            .phone-codes {
              margin-top: 54px;
              position: relative;

              .phone-icon,
              .phone-codes-dropdown-item-selected {
                width: 92px;
                @include flexible("", center, center);
                border-bottom: 3px solid $primary-color-light;
                padding-bottom: 5px;
                cursor: pointer;
              }

              .phone-codes-dropdown-item-selected {
                gap: 10px;
              }

              .open-phone-codes-dropdown {
                position: absolute;
                overflow-y: scroll;
                height: 150px;
                background-color: #fff;
                display: block;
              }

              .phone-codes-dropdown-item {
                width: 92px;
                cursor: pointer;
                padding: 3px;
                @include flexible("", "", center);
                gap: 10px;
              }

              .phone-codes-dropdown-item-border-bottom {
                @include input($primary-color-light, $primary-color-light);
              }

              .phone-codes-dropdown {
                display: none;
              }
            }
          }
        }
      }

      .left-block,
      .right-block {
        color: $secondary-color;
        font-size: 18px;
        width: 549px;

        &__title {
          font-size: 24px;
          font-weight: normal;
          margin-bottom: 12px;
        }

        &__text {
          display: block;
          margin-bottom: 23px;
        }
      }

      .left-block {
        &__title {
          text-align: end;
        }

        &__text {
          text-align: end;
        }
      }

      .reseller-panel-link {
        cursor: pointer;
        width: max-content;
        padding: 20px;
        font-size: 24px;
        background: $primary-color-light;
        border-radius: $border-radius;
        color: #ffffff;
        @include flexible("", center, center);
        gap: 49px;
        width: 100%;
      }

      .bottom-section {
        margin-top: 53px;
        margin-bottom: 33px;
        @include flexible("", center, space-between);
        gap: 26px;

        .partner-type {
          @include flexible(column, "", space-between);

          &__title {
            font-size: 16px;
            color: $primary-color-light;
            margin-bottom: 32px;
          }

          .reseller,
          .referal {
            @include flexible("", center, "");
            color: $secondary-color;
            font-size: 16px;
            margin-bottom: 8px;
            cursor: pointer;
          }
        }

        .divider {
          border-left: 1px solid $secondary-color;
          height: 108px;
        }

        .checkboxes-group {
          color: $secondary-color;
          @include flexible("column", "", space-between);

          .all {
            color: $primary-color-light;
            font-weight: bold;
            margin-bottom: 12px;
            @include flexible("", center, center);
            cursor: pointer;
          }

          .usage,
          .privacy {
            margin-bottom: 8px;
            @include flexible("", center, "");
          }

          a {
            color: $secondary-color;
            text-decoration: none;
            cursor: pointer;
          }
        }

        .checkbox {
          color: $secondary-color;
          width: 22px;
          height: 22px;
          margin-right: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 1700px) {
  .left-block,
  .right-block {
    width:415px  !important;
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1600px) {
  .reseller-section__content-title {
    font-size: 50px;
  }
}


@media screen and (max-width: 592px) {
  .become-reseller-form,
  .left-block,
  .right-block {
    width: 352px !important;
    margin: auto;
  }

  .left-block,
  .right-block {
    grid-column: 1/3 !important ;
  }

  .bottom-section {
    gap: 18px !important;
  }

  .reseller-panel-link {
    width: 100% !important;
    padding: 20px 40px !important;
  }
}

@media screen and (max-width: 1177px) {
  .reseller-section__content__wrapper {
    gap: 0 !important;
  }

  .left-block {
    margin-top: 121px;
    grid-row: 2/3;
    grid-column: 1/2;
  }

  .right-block {
    grid-row: 3/4 !important;
    grid-column: 1/2;
  }
}

@media screen and (max-width: 1370px) {
  .reseller-section__content__wrapper {
    display: grid;
    gap: 61px;

    .become-reseller-form-content {
      margin: 0 !important;
      grid-column: 1/3;
      grid-row: 1/2;
      @include flexible(column, center, "");
    }

    .left-block,
    .right-block {
      font-size: 16px;
    }

    .left-block,
    .right-block {
      grid-row: 2/3;
    }
  }
}

