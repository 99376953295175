@import "../../../../public/stylesheets/index.scss";

.login-page {
  padding-top: 140px;
  padding-bottom: 75px;
  position: relative;
  @include flexible(column, center, center);
  @include backgroundImage("../../../assets/images/loginBackground.png", cover);

  .login-page__layer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(256, 256, 256, 0.8);
  }

  .login-page-wrapper {
    @include flexible("", "", space-between);

    .login-page__image-block {
      width: 44%;
      height: 700px;
      margin-right: 182px;
      @include backgroundImage("../../../assets/images/img1.png", contain);
    }

    .login-page__form {
      padding: 75px 100px;
      background: rgba(255, 255, 255, 0.8);
      border: 2px solid #fff;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      @include flexible(column, "", space-between);

      .login-page__form-second {
        margin-top: 69px;
      }

      .login-page__form__title {
        font-weight: 700;
        font-size: 23px;
        color: $primary-color-light;
      }

      .login-page-btn {
        margin: 30px 0;
        border: transparent;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        background: #fff;
        color: $primary-color-light;
        padding: 12px 24px;
        font-size: 16px;
        font-weight: 500;
        float: right;
        cursor: pointer;

        &:hover {
          color: $secondary-color;
        }
      }

      .login-page_input {
        margin-top: 50px;
        @include input($primary-color-light, $secondary-color);
      }

      .form-bottom {
        @include flexible("", center, space-between);
        margin-bottom: 20px;

        a {
          font-style: italic;
          font-size: 15px;
          font-weight: 600;
          text-decoration: none;
          color: #3d53ed;
        }
      }

      .login-form-content {
        width: 428px;
      }

      .login-section-bottom {
        width: 428px;

        .login-page__form__bottom-text {
          margin-top: 15px;
          font-size: 17px;
          color: $primary-color;
        }
      }
    }
  }

  .login-page__bottom {
    position: absolute;
    bottom: 0;
    height: 390px;
    width: 100%;
    background: linear-gradient(180deg, #ffffff00 -5%, #ffffff 100%) 0% 0%
      no-repeat padding-box;
  }
}

.code-modal-content {
  text-align: center;

  .code-modal-title {
    color: $primary-color-light;
    font-weight: 400;
    font-size: 33px;
  }

  .code-modal-form {
    @include flexible(column, flex-end, flex-end);
    width: 422px;
    margin: auto;
    margin-top: 50px;

    &_inp {
      @include input($primary-color-light, $primary-color-light);
      margin-top: 0;
    }
  }

  .code-modal-text {
    color: $primary-color-light;
    font-size: 18px;
    text-align: center;
    display: block;
  }
}

@media screen and (max-width: 1416px) {
  .login-page__image-block {
    width: 356px !important;
  }
}

@media screen and (max-width: 1600px) {
  .login-page {
    padding-top: 110px;
  }

  .login-page__form__title {
    font-size: 20px !important;
  }

  .login-page__form {
    padding: 40px 50px !important;

    .login-page__form-second {
      margin-top: 0 !important;
    }

    .login-page-btn {
      margin: 10px 0 !important;
    }

    .login-page_input {
      margin-top: 30px;
    }

    .form-bottom {
      margin-bottom: 0 !important;
    }
  }

  .login-page__form__bottom-text {
    margin-top: 0;
  }

  .login-page__image-block {
    height: 650px !important;
  }

  .login-form-content,
  .login-section-bottom {
    width: 385px !important;
  }
}

@media screen and (max-width: 1300px) {
  .login-page-wrapper {
    @include flexible(column, center, "");

    .login-page__image-block {
      margin-right: 0 !important;
      height: 500px !important;
    }
  }
}

@media screen and (max-width: 690px) {
  .login-page__form {
    padding: 75px 56px !important;
  }
}

@media screen and (max-width: 592px) {
  .login-form-content {
    width: 352px !important;
  }

  .login-section-bottom {
    width: 352px !important;
  }

  .code-modal-form {
    width: auto !important;
  }
}

@media screen and (max-width: 499px) {
  .login-page__image-block {
    width: 273px !important;
  }

  .login-page__form-second,
  .login-page__form-first {
    .login-page__form__title {
      font-size: 16px !important;
    }
  }

  .login-page-btn {
    font-size: 11px !important;
  }

  .form-bottom {
    a {
      font-size: 12px !important;
    }
  }

  .login-form-content {
    width: 247px !important;
  }

  .login-section-bottom {
    width: 247px !important;

    .login-page__form__title {
      font-size: 16px !important;
    }

    .login-page__form__bottom-text {
      font-size: 14px !important;
      color: $primary-color;
    }
  }

  .login-page__form {
    width: 334px !important;
    padding-left: 40px !important;
  }
}
