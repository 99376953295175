@import "../../../public/stylesheets/index.scss";

.payment-section {
  width: 100%;
  padding: 120px 0 190px;
  @include backgroundImage("../../assets/images/activation.png", cover);
  @include flexible(column, center, center);
  position: relative;

  &__wrapper {
    @include flexible(column, center, "");

    .title {
      color: $primary-color;
      font-size: 88px;
      font-weight: 400;
    }

    .payment-type {
      @include flexible("", center, space-between);
      background-color: #fff;
      border-radius: 38px;
      box-shadow: $box-shadow;
      padding: 4px;
      margin-top: 67px;

      &__direct-pay,
      &__coupon {
        font-size: 24px;
        border-radius: $border-radius;
        color: $primary-color-light;
        cursor: pointer;
        padding: 18px 37px;

        &.active {
          background: #fd9598 !important;
        }
      }
    }

    .activation {
      margin-top: 67px;
      width: 428px;
      color: $primary-color-light;

      &-form {
        min-height: 353px;
        
        &__inp {
          @include input($secondary-color, $secondary-color);
        }
      }

      .cmi-container {
        @include flexible("", center, space-between);

        .cmi-container-vimamaster {
          @include flexible("", center, center);
        }
      }

      .warning {
        font-size: 14px;

        .checkboxes-group {
          margin-top: 40px;

          .checkbox {
            font-size: 16px;
            @include flexible("", center, "");
            gap: 10px;
            margin-bottom: 10px;

            a {
              text-decoration: none;
              color: $primary-color-light;
            }

            .check-btn {
              transform: scale(1.5);
            }

            .agree-with-all {
              font-size: 22px;
              font-weight: 600;
            }
          }
        }
      }
    }

    &__main-content{
      @include flexible("","","");
      gap: 90px;
    }

    &-desc {
      width: 428px;
      margin-top: 45px;

      .desc__text {
        font-size: 14px;

        &__subtext {
          font-weight: 600;
        }
      }

      .desc__title {
        font-size: 24px;
        margin: 20px 0;
      }

      .steps {
        margin-left: 19px;
        font-size: 18px;
        margin-bottom: 10px;
      }

      .bottom-text {
        font-size: 18px;
      }
    }
  }

  .activate-by-voucher-code {
    color: $primary-color-light;
    text-align: end;
    font-size: 14px;
    @include flexible("", "", flex-end);
    gap: 22px;
    position: absolute;
    bottom: 60px;
    right: 60px;
  }

  
}

.vocher-modal-title {
  color: $primary-color-light;
  font-weight: 400;
  font-size: 33px;
}

.modal-content {
  text-align: center;

  .voucher-form {
    @include flexible(column, flex-end, flex-end);
    width: 422px;
    margin: auto;
    margin-top: 50px;

    &__inp {
      @include input($primary-color-light, $primary-color-light);
    }
  }
}

@media screen and (max-width: 1600px) {
  .title {
    font-size: 50px !important;
  }


  .activation{
    margin-top: 0 !important;
  }

  .payment-type {
    margin-top: 35px !important;
    
    &__direct-pay,
    &__coupon {
      font-weight: 500;
      font-size: 16px !important;

      &.active {
        font-size: 16px !important;
      }
    }
  }

  .payment-section__wrapper-desc {
    width: 352px !important;

    .desc__title {
      font-size: 16px;
    }

    .steps {
      font-size: 16px;
    }

    .bottom-text {
      font-size: 16px;
    }
  }

  .checkboxes-group {
    .checkbox {
      font-size: 14px !important;

      .agree-with-all {
        font-size: 18px !important;
      }
    }
  }
}

@media screen and (max-width:1000px) {
  .payment-section__wrapper__main-content{
    @include flexible(column,"","");
    gap: 0px !important;
  }
}

@media screen and (max-width: 500px) {
  .payment-type {
    &__direct-pay,
    &__coupon {
      padding: 15px 23px !important ;
    }
  }
}


@media screen and (max-width:592px) {
  .activation{
    width: 352px !important;
  }

  .voucher-form{
    width: auto !important;
  }
}

